<template>
  <div>
    <dateTimePicker
      :title="fieldAttributes.name"
      v-model="localValue"
      mindate="0"
    ></dateTimePicker>
    <input type="hidden" :value="localValue" :name="field" />
  </div>
</template>
<script>
import dateTimePicker from "@/commonComponents/legacyDateTimePicker.vue";
import { getfieldAttrInput } from "@/js/helper.js";
export default {
  components: { dateTimePicker },
  props: ["result", "field", "fieldAttributes","value"],
  computed: {
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes);
    },
    localValue: {
      get() {
        let value = Math.round(new Date().getTime() / 1000);
        if (typeof this.value != "undefined") {
          value = this.value;
        }
        return value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
};
</script>